import { __ } from './object.functions';

export class LanguageHelper {
    static Languages = [
        {
            value: `af`,
            displayName: `Afrikaans`
        },
        {
            value: `af-ZA`,
            displayName: `Afrikaans (South Africa)`
        },
        {
            value: `am-ET`,
            displayName: `Amharic (Ethiopia)`
        },
        {
            value: `ar`,
            displayName: `Arabic`
        },
        {
            value: `ar-AE`,
            displayName: `Arabic (U.A.E.)`
        },
        {
            value: `ar-BH`,
            displayName: `Arabic (Bahrain)`
        },
        {
            value: `ar-DZ`,
            displayName: `Arabic (Algeria)`
        },
        {
            value: `ar-EG`,
            displayName: `Arabic (Egypt)`
        },
        {
            value: `ar-IQ`,
            displayName: `Arabic (Iraq)`
        },
        {
            value: `ar-JO`,
            displayName: `Arabic (Jordan)`
        },
        {
            value: `ar-KW`,
            displayName: `Arabic (Kuwait)`
        },
        {
            value: `ar-LB`,
            displayName: `Arabic (Lebanon)`
        },
        {
            value: `ar-LY`,
            displayName: `Arabic (Libya)`
        },
        {
            value: `ar-MA`,
            displayName: `Arabic (Morocco)`
        },
        {
            value: `ar-OM`,
            displayName: `Arabic (Oman)`
        },
        {
            value: `ar-QA`,
            displayName: `Arabic (Qatar)`
        },
        {
            value: `ar-SA`,
            displayName: `Arabic (Saudi Arabia)`
        },
        {
            value: `ar-SY`,
            displayName: `Arabic (Syria)`
        },
        {
            value: `ar-TN`,
            displayName: `Arabic (Tunisia)`
        },
        {
            value: `ar-YE`,
            displayName: `Arabic (Yemen)`
        },
        {
            value: `arn-CL`,
            displayName: `Mapudungun (Chile)`
        },
        {
            value: `as-IN`,
            displayName: `Assamese (India)`
        },
        {
            value: `az`,
            displayName: `Azeri`
        },
        {
            value: `az-Cyrl-AZ`,
            displayName: `Azeri (Cyrillic) (Azerbaijan)`
        },
        {
            value: `az-Latn-AZ`,
            displayName: `Azeri (Latin) (Azerbaijan)`
        },
        {
            value: `ba-RU`,
            displayName: `Bashkir (Russia)`
        },
        {
            value: `be`,
            displayName: `Belarusian`
        },
        {
            value: `be-BY`,
            displayName: `Belarusian (Belarus)`
        },
        {
            value: `bg`,
            displayName: `Bulgarian`
        },
        {
            value: `bg-BG`,
            displayName: `Bulgarian (Bulgaria)`
        },
        {
            value: `bn-BD`,
            displayName: `Bengali (Bangladesh)`
        },
        {
            value: `bn-IN`,
            displayName: `Bengali (India)`
        },
        {
            value: `bo-CN`,
            displayName: `Tibetan (People's Republic of China)`
        },
        {
            value: `br-FR`,
            displayName: `Breton (France)`
        },
        {
            value: `bs-Cyrl-BA`,
            displayName: `Bosnian (Cyrillic) (Bosnia and Herzegovina)`
        },
        {
            value: `bs-Latn-BA`,
            displayName: `Bosnian (Latin) (Bosnia and Herzegovina)`
        },
        {
            value: `ca`,
            displayName: `Catalan`
        },
        {
            value: `ca-ES`,
            displayName: `Catalan (Catalan)`
        },
        {
            value: `co-FR`,
            displayName: `Corsican (France)`
        },
        {
            value: `cs`,
            displayName: `Czech`
        },
        {
            value: `cs-CZ`,
            displayName: `Czech (Czech Republic)`
        },
        {
            value: `cy-GB`,
            displayName: `Welsh (United Kingdom)`
        },
        {
            value: `da`,
            displayName: `Danish`
        },
        {
            value: `da-DK`,
            displayName: `Danish (Denmark)`
        },
        {
            value: `de`,
            displayName: `German`
        },
        {
            value: `de-AT`,
            displayName: `German (Austria)`
        },
        {
            value: `de-CH`,
            displayName: `German (Switzerland)`
        },
        {
            value: `de-DE`,
            displayName: `German (Germany)`
        },
        {
            value: `de-LI`,
            displayName: `German (Liechtenstein)`
        },
        {
            value: `de-LU`,
            displayName: `German (Luxembourg)`
        },
        {
            value: `dsb-DE`,
            displayName: `Lower Sorbian (Germany)`
        },
        {
            value: `dv`,
            displayName: `Divehi`
        },
        {
            value: `dv-MV`,
            displayName: `Divehi (Maldives)`
        },
        {
            value: `el`,
            displayName: `Greek`
        },
        {
            value: `el-GR`,
            displayName: `Greek (Greece)`
        },
        {
            value: `en`,
            displayName: `English`
        },
        {
            value: `en-029`,
            displayName: `English (Caribbean)`
        },
        {
            value: `en-AU`,
            displayName: `English (Australia)`
        },
        {
            value: `en-BZ`,
            displayName: `English (Belize)`
        },
        {
            value: `en-CA`,
            displayName: `English (Canada)`
        },
        {
            value: `en-GB`,
            displayName: `English (United Kingdom)`
        },
        {
            value: `en-IE`,
            displayName: `English (Ireland)`
        },
        {
            value: `en-IN`,
            displayName: `English (India)`
        },
        {
            value: `en-JM`,
            displayName: `English (Jamaica)`
        },
        {
            value: `en-MY`,
            displayName: `English (Malaysia)`
        },
        {
            value: `en-NZ`,
            displayName: `English (New Zealand)`
        },
        {
            value: `en-PH`,
            displayName: `English (Republic of the Philippines)`
        },
        {
            value: `en-SG`,
            displayName: `English (Singapore)`
        },
        {
            value: `en-TT`,
            displayName: `English (Trinidad and Tobago)`
        },
        {
            value: `en-US`,
            displayName: `English (United States)`
        },
        {
            value: `en-ZA`,
            displayName: `English (South Africa)`
        },
        {
            value: `en-ZW`,
            displayName: `English (Zimbabwe)`
        },
        {
            value: `es`,
            displayName: `Spanish`
        },
        {
            value: `es-AR`,
            displayName: `Spanish (Argentina)`
        },
        {
            value: `es-BO`,
            displayName: `Spanish (Bolivia)`
        },
        {
            value: `es-CL`,
            displayName: `Spanish (Chile)`
        },
        {
            value: `es-CO`,
            displayName: `Spanish (Colombia)`
        },
        {
            value: `es-CR`,
            displayName: `Spanish (Costa Rica)`
        },
        {
            value: `es-DO`,
            displayName: `Spanish (Dominican Republic)`
        },
        {
            value: `es-EC`,
            displayName: `Spanish (Ecuador)`
        },
        {
            value: `es-ES`,
            displayName: `Spanish (Spain)`
        },
        {
            value: `es-GT`,
            displayName: `Spanish (Guatemala)`
        },
        {
            value: `es-HN`,
            displayName: `Spanish (Honduras)`
        },
        {
            value: `es-MX`,
            displayName: `Spanish (Mexico)`
        },
        {
            value: `es-NI`,
            displayName: `Spanish (Nicaragua)`
        },
        {
            value: `es-PA`,
            displayName: `Spanish (Panama)`
        },
        {
            value: `es-PE`,
            displayName: `Spanish (Peru)`
        },
        {
            value: `es-PR`,
            displayName: `Spanish (Puerto Rico)`
        },
        {
            value: `es-PY`,
            displayName: `Spanish (Paraguay)`
        },
        {
            value: `es-SV`,
            displayName: `Spanish (El Salvador)`
        },
        {
            value: `es-US`,
            displayName: `Spanish (United States)`
        },
        {
            value: `es-UY`,
            displayName: `Spanish (Uruguay)`
        },
        {
            value: `es-VE`,
            displayName: `Spanish (Venezuela)`
        },
        {
            value: `et`,
            displayName: `Estonian`
        },
        {
            value: `et-EE`,
            displayName: `Estonian (Estonia)`
        },
        {
            value: `eu`,
            displayName: `Basque`
        },
        {
            value: `eu-ES`,
            displayName: `Basque (Basque)`
        },
        {
            value: `fa`,
            displayName: `Persian`
        },
        {
            value: `fa-IR`,
            displayName: `Persian (Iran)`
        },
        {
            value: `fi`,
            displayName: `Finnish`
        },
        {
            value: `fi-FI`,
            displayName: `Finnish (Finland)`
        },
        {
            value: `fil-PH`,
            displayName: `Filipino (Philippines)`
        },
        {
            value: `fo`,
            displayName: `Faroese`
        },
        {
            value: `fo-FO`,
            displayName: `Faroese (Faroe Islands)`
        },
        {
            value: `fr`,
            displayName: `French`
        },
        {
            value: `fr-BE`,
            displayName: `French (Belgium)`
        },
        {
            value: `fr-CA`,
            displayName: `French (Canada)`
        },
        {
            value: `fr-CH`,
            displayName: `French (Switzerland)`
        },
        {
            value: `fr-FR`,
            displayName: `French (France)`
        },
        {
            value: `fr-LU`,
            displayName: `French (Luxembourg)`
        },
        {
            value: `fr-MC`,
            displayName: `French (Principality of Monaco)`
        },
        {
            value: `fy-NL`,
            displayName: `Frisian (Netherlands)`
        },
        {
            value: `ga-IE`,
            displayName: `Irish (Ireland)`
        },
        {
            value: `gd-GB`,
            displayName: `Scottish Gaelic (United Kingdom)`
        },
        {
            value: `gl`,
            displayName: `Galician`
        },
        {
            value: `gl-ES`,
            displayName: `Galician (Galician)`
        },
        {
            value: `gsw-FR`,
            displayName: `Alsatian (France)`
        },
        {
            value: `gu`,
            displayName: `Gujarati`
        },
        {
            value: `gu-IN`,
            displayName: `Gujarati (India)`
        },
        {
            value: `ha-Latn-NG`,
            displayName: `Hausa (Latin) (Nigeria)`
        },
        {
            value: `he`,
            displayName: `Hebrew`
        },
        {
            value: `he-IL`,
            displayName: `Hebrew (Israel)`
        },
        {
            value: `hi`,
            displayName: `Hindi`
        },
        {
            value: `hi-IN`,
            displayName: `Hindi (India)`
        },
        {
            value: `hr`,
            displayName: `Croatian`
        },
        {
            value: `hr-BA`,
            displayName: `Croatian (Latin) (Bosnia and Herzegovina)`
        },
        {
            value: `hr-HR`,
            displayName: `Croatian (Croatia)`
        },
        {
            value: `hsb-DE`,
            displayName: `Upper Sorbian (Germany)`
        },
        {
            value: `hu`,
            displayName: `Hungarian`
        },
        {
            value: `hu-HU`,
            displayName: `Hungarian (Hungary)`
        },
        {
            value: `hy`,
            displayName: `Armenian`
        },
        {
            value: `hy-AM`,
            displayName: `Armenian (Armenia)`
        },
        {
            value: `id`,
            displayName: `Indonesian`
        },
        {
            value: `id-ID`,
            displayName: `Indonesian (Indonesia)`
        },
        {
            value: `ig-NG`,
            displayName: `Igbo (Nigeria)`
        },
        {
            value: `ii-CN`,
            displayName: `Yi (People's Republic of China)`
        },
        {
            value: `is`,
            displayName: `Icelandic`
        },
        {
            value: `is-IS`,
            displayName: `Icelandic (Iceland)`
        },
        {
            value: `it`,
            displayName: `Italian`
        },
        {
            value: `it-CH`,
            displayName: `Italian (Switzerland)`
        },
        {
            value: `it-IT`,
            displayName: `Italian (Italy)`
        },
        {
            value: `iu-Cans-CA`,
            displayName: `Inuktitut (Syllabics) (Canada)`
        },
        {
            value: `iu-Latn-CA`,
            displayName: `Inuktitut (Latin) (Canada)`
        },
        {
            value: `ja`,
            displayName: `Japanese`
        },
        {
            value: `ja-JP`,
            displayName: `Japanese (Japan)`
        },
        {
            value: `ka`,
            displayName: `Georgian`
        },
        {
            value: `ka-GE`,
            displayName: `Georgian (Georgia)`
        },
        {
            value: `kk`,
            displayName: `Kazakh`
        },
        {
            value: `kk-KZ`,
            displayName: `Kazakh (Kazakhstan)`
        },
        {
            value: `kl-GL`,
            displayName: `Greenlandic (Greenland)`
        },
        {
            value: `km-KH`,
            displayName: `Khmer (Cambodia)`
        },
        {
            value: `kn`,
            displayName: `Kannada`
        },
        {
            value: `kn-IN`,
            displayName: `Kannada (India)`
        },
        {
            value: `ko`,
            displayName: `Korean`
        },
        {
            value: `ko-KR`,
            displayName: `Korean (Korea)`
        },
        {
            value: `kok`,
            displayName: `Konkani`
        },
        {
            value: `kok-IN`,
            displayName: `Konkani (India)`
        },
        {
            value: `ky`,
            displayName: `Kyrgyz`
        },
        {
            value: `ky-KG`,
            displayName: `Kyrgyz (Kyrgyzstan)`
        },
        {
            value: `lb-LU`,
            displayName: `Luxembourgish (Luxembourg)`
        },
        {
            value: `lo-LA`,
            displayName: `Lao (Lao P.D.R.)`
        },
        {
            value: `lt`,
            displayName: `Lithuanian`
        },
        {
            value: `lt-LT`,
            displayName: `Lithuanian (Lithuania)`
        },
        {
            value: `lv`,
            displayName: `Latvian`
        },
        {
            value: `lv-LV`,
            displayName: `Latvian (Latvia)`
        },
        {
            value: `mi-NZ`,
            displayName: `Maori (New Zealand)`
        },
        {
            value: `mk`,
            displayName: `Macedonian`
        },
        {
            value: `mk-MK`,
            displayName: `Macedonian (Former Yugoslav Republic of Macedonia)`
        },
        {
            value: `ml-IN`,
            displayName: `Malayalam (India)`
        },
        {
            value: `mn`,
            displayName: `Mongolian`
        },
        {
            value: `mn-MN`,
            displayName: `Mongolian (Cyrillic) (Mongolia)`
        },
        {
            value: `mn-Mong-CN`,
            displayName: `Mongolian (Traditional Mongolian) (People's Republic of China)`
        },
        {
            value: `moh-CA`,
            displayName: `Mohawk (Canada)`
        },
        {
            value: `mr`,
            displayName: `Marathi`
        },
        {
            value: `mr-IN`,
            displayName: `Marathi (India)`
        },
        {
            value: `ms`,
            displayName: `Malay`
        },
        {
            value: `ms-BN`,
            displayName: `Malay (Brunei Darussalam)`
        },
        {
            value: `ms-MY`,
            displayName: `Malay (Malaysia)`
        },
        {
            value: `mt-MT`,
            displayName: `Maltese (Malta)`
        },
        {
            value: `nb-NO`,
            displayName: `Norwegian, Bokmål (Norway)`
        },
        {
            value: `ne-NP`,
            displayName: `Nepali (Nepal)`
        },
        {
            value: `nl`,
            displayName: `Dutch`
        },
        {
            value: `nl-BE`,
            displayName: `Dutch (Belgium)`
        },
        {
            value: `nl-NL`,
            displayName: `Dutch (Netherlands)`
        },
        {
            value: `nn-NO`,
            displayName: `Norwegian, Nynorsk (Norway)`
        },
        {
            value: `no`,
            displayName: `Norwegian`
        },
        {
            value: `nso-ZA`,
            displayName: `Sesotho sa Leboa (South Africa)`
        },
        {
            value: `oc-FR`,
            displayName: `Occitan (France)`
        },
        {
            value: `or-IN`,
            displayName: `Oriya (India)`
        },
        {
            value: `pa`,
            displayName: `Punjabi`
        },
        {
            value: `pa-IN`,
            displayName: `Punjabi (India)`
        },
        {
            value: `pl`,
            displayName: `Polish`
        },
        {
            value: `pl-PL`,
            displayName: `Polish (Poland)`
        },
        {
            value: `prs-AF`,
            displayName: `Dari (Afghanistan)`
        },
        {
            value: `ps-AF`,
            displayName: `Pashto (Afghanistan)`
        },
        {
            value: `pt`,
            displayName: `Portuguese`
        },
        {
            value: `pt-BR`,
            displayName: `Portuguese (Brazil)`
        },
        {
            value: `pt-PT`,
            displayName: `Portuguese (Portugal)`
        },
        {
            value: `qut-GT`,
            displayName: `K'iche (Guatemala)`
        },
        {
            value: `quz-BO`,
            displayName: `Quechua (Bolivia)`
        },
        {
            value: `quz-EC`,
            displayName: `Quechua (Ecuador)`
        },
        {
            value: `quz-PE`,
            displayName: `Quechua (Peru)`
        },
        {
            value: `rm-CH`,
            displayName: `Romansh (Switzerland)`
        },
        {
            value: `ro`,
            displayName: `Romanian`
        },
        {
            value: `ro-RO`,
            displayName: `Romanian (Romania)`
        },
        {
            value: `ru`,
            displayName: `Russian`
        },
        {
            value: `ru-RU`,
            displayName: `Russian (Russia)`
        },
        {
            value: `rw-RW`,
            displayName: `Kinyarwanda (Rwanda)`
        },
        {
            value: `sa`,
            displayName: `Sanskrit`
        },
        {
            value: `sa-IN`,
            displayName: `Sanskrit (India)`
        },
        {
            value: `sah-RU`,
            displayName: `Yakut (Russia)`
        },
        {
            value: `se-FI`,
            displayName: `Sami (Northern) (Finland)`
        },
        {
            value: `se-NO`,
            displayName: `Sami (Northern) (Norway)`
        },
        {
            value: `se-SE`,
            displayName: `Sami (Northern) (Sweden)`
        },
        {
            value: `si-LK`,
            displayName: `Sinhala (Sri Lanka)`
        },
        {
            value: `sk`,
            displayName: `Slovak`
        },
        {
            value: `sk-SK`,
            displayName: `Slovak (Slovakia)`
        },
        {
            value: `sl`,
            displayName: `Slovenian`
        },
        {
            value: `sl-SI`,
            displayName: `Slovenian (Slovenia)`
        },
        {
            value: `sma-NO`,
            displayName: `Sami (Southern) (Norway)`
        },
        {
            value: `sma-SE`,
            displayName: `Sami (Southern) (Sweden)`
        },
        {
            value: `smj-NO`,
            displayName: `Sami (Lule) (Norway)`
        },
        {
            value: `smj-SE`,
            displayName: `Sami (Lule) (Sweden)`
        },
        {
            value: `smn-FI`,
            displayName: `Sami (Inari) (Finland)`
        },
        {
            value: `sms-FI`,
            displayName: `Sami (Skolt) (Finland)`
        },
        {
            value: `sq`,
            displayName: `Albanian`
        },
        {
            value: `sq-AL`,
            displayName: `Albanian (Albania)`
        },
        {
            value: `sr`,
            displayName: `Serbian`
        },
        {
            value: `sr-Cyrl-BA`,
            displayName: `Serbian (Cyrillic) (Bosnia and Herzegovina)`
        },
        {
            value: `sr-Cyrl-CS`,
            displayName: `Serbian (Cyrillic) (Serbia and Montenegro (Former))`
        },
        {
            value: `sr-Cyrl-ME`,
            displayName: `Serbian (Cyrillic) (Montenegro)`
        },
        {
            value: `sr-Cyrl-RS`,
            displayName: `Serbian (Cyrillic) (Serbia)`
        },
        {
            value: `sr-Latn-BA`,
            displayName: `Serbian (Latin) (Bosnia and Herzegovina)`
        },
        {
            value: `sr-Latn-CS`,
            displayName: `Serbian (Latin) (Serbia and Montenegro (Former))`
        },
        {
            value: `sr-Latn-ME`,
            displayName: `Serbian (Latin) (Montenegro)`
        },
        {
            value: `sr-Latn-RS`,
            displayName: `Serbian (Latin) (Serbia)`
        },
        {
            value: `sv`,
            displayName: `Swedish`
        },
        {
            value: `sv-FI`,
            displayName: `Swedish (Finland)`
        },
        {
            value: `sv-SE`,
            displayName: `Swedish (Sweden)`
        },
        {
            value: `sw`,
            displayName: `Kiswahili`
        },
        {
            value: `sw-KE`,
            displayName: `Kiswahili (Kenya)`
        },
        {
            value: `syr`,
            displayName: `Syriac`
        },
        {
            value: `syr-SY`,
            displayName: `Syriac (Syria)`
        },
        {
            value: `ta`,
            displayName: `Tamil`
        },
        {
            value: `ta-IN`,
            displayName: `Tamil (India)`
        },
        {
            value: `te`,
            displayName: `Telugu`
        },
        {
            value: `te-IN`,
            displayName: `Telugu (India)`
        },
        {
            value: `tg-Cyrl-TJ`,
            displayName: `Tajik (Cyrillic) (Tajikistan)`
        },
        {
            value: `th`,
            displayName: `Thai`
        },
        {
            value: `th-TH`,
            displayName: `Thai (Thailand)`
        },
        {
            value: `tk-TM`,
            displayName: `Turkmen (Turkmenistan)`
        },
        {
            value: `tn-ZA`,
            displayName: `Setswana (South Africa)`
        },
        {
            value: `tr`,
            displayName: `Turkish`
        },
        {
            value: `tr-TR`,
            displayName: `Turkish (Turkey)`
        },
        {
            value: `tt`,
            displayName: `Tatar`
        },
        {
            value: `tt-RU`,
            displayName: `Tatar (Russia)`
        },
        {
            value: `tzm-Latn-DZ`,
            displayName: `Tamazight (Latin) (Algeria)`
        },
        {
            value: `ug-CN`,
            displayName: `Uyghur (People's Republic of China)`
        },
        {
            value: `uk`,
            displayName: `Ukrainian`
        },
        {
            value: `uk-UA`,
            displayName: `Ukrainian (Ukraine)`
        },
        {
            value: `ur`,
            displayName: `Urdu`
        },
        {
            value: `ur-PK`,
            displayName: `Urdu (Islamic Republic of Pakistan)`
        },
        {
            value: `uz`,
            displayName: `Uzbek`
        },
        {
            value: `uz-Cyrl-UZ`,
            displayName: `Uzbek (Cyrillic) (Uzbekistan)`
        },
        {
            value: `uz-Latn-UZ`,
            displayName: `Uzbek (Latin) (Uzbekistan)`
        },
        {
            value: `vi`,
            displayName: `Vietnamese`
        },
        {
            value: `vi-VN`,
            displayName: `Vietnamese (Vietnam)`
        },
        {
            value: `wo-SN`,
            displayName: `Wolof (Senegal)`
        },
        {
            value: `xh-ZA`,
            displayName: `isiXhosa (South Africa)`
        },
        {
            value: `yo-NG`,
            displayName: `Yoruba (Nigeria)`
        },
        {
            value: `zh-Hans`,
            displayName: `Chinese (Simplified)`
        },
        {
            value: `zh-Hant`,
            displayName: `Chinese (Traditional)`
        },
        {
            value: `zh-CN`,
            displayName: `Chinese (People's Republic of China)`
        },
        {
            value: `zh-HK`,
            displayName: `Chinese (Hong Kong S.A.R.)`
        },
        {
            value: `zh-MO`,
            displayName: `Chinese (Macao S.A.R.)`
        },
        {
            value: `zh-SG`,
            displayName: `Chinese (Singapore)`
        },
        {
            value: `zh-TW`,
            displayName: `Chinese (Taiwan)`
        },
        {
            value: `zu-ZA`,
            displayName: `isiZulu (South Africa)`
        }
    ]

    static GetLanguages() {
        return this.Languages;
    }

    static GetLanguageByNameOrNull(displayName: string): any {
        const result = LanguageHelper.GetLanguages().find((language: any) => language.displayName === displayName);
        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }
        return result;
    }

    static GetLanguageByValueOrNull(value: string): any {
        const result = LanguageHelper.GetLanguages().find((language: any) => language.value === value);
        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }
        return result;
    }

    static GetLanguageByShortNameOrNull(value: string): any {
        const result = LanguageHelper.GetLanguages().find((language: any) => language.shortName === value);
        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }
        return result;
    }
}
